<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <b-row>
            <b-col lg="8">
              <div>
                <!-- Media -->
                <b-media class="mb-2">
                  <template #aside>
                    <b-avatar
                      ref="previewEl"
                      :src="user.avatar ? user.avatar.image : null"
                      :text="user.name"
                      :variant="`light-primary`"
                      size="90px"
                      rounded
                    />
                  </template>
                  <h4 class="mb-1">
                    {{ user.name }}
                  </h4>
                  <small>Plateforme :</small>
                  <b-link :to="{name:'platforms-view', params: { id: user.platform ? user.platform.uuid : null }}">
                    {{ user.platform ? user.platform.name : '' }}
                  </b-link>
                </b-media>

                <!-- Platform Info: Input Fields -->

                <validation-observer ref="platformValidation">
                  <b-form @submit.prevent="validationForm">
                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <b-form-group
                          label="Nom"
                          label-for="name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="name"
                            rules="required|max:100|min:3"
                          >
                            <b-form-input
                              id="name"
                              v-model="user.name"
                              placeholder="ccup"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="server_errors && server_errors['name']"
                              class="text-danger"
                            >{{ server_errors['name'][0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Field: email -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <b-form-group
                          label="Email"
                          label-for="subdomain"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="subdomain"
                            rules="required|max:100|min:3"
                          >
                            <b-form-input
                              id="subdomain"
                              v-model="user.email"
                              placeholder="ccup"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="server_errors && server_errors['subdomain']"
                              class="text-danger"
                            >{{ server_errors['subdomain'][0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        md="6"
                        lg="4"
                      >
                        <b-form-group
                          label="langage choisi"
                          label-for="language"
                        >
                          <v-select
                            v-model="language"
                            :dir="'ltr'"
                            :options="languagesOptions"
                            :clearable="false"
                            input-id="language"
                          />
                          <small
                            v-if="server_errors && server_errors['competition']"
                            class="text-danger"
                          >{{ server_errors['competition'][0] }}</small>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Description -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="6"
                      >
                        <b-form-group
                          label="Description"
                          label-for="description"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="description"
                            rules="max:100|min:3"
                          >
                            <b-form-textarea
                              id="textarea-default"
                              v-model="user.description"
                              placeholder="Textarea"
                              rows="3"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small
                              v-if="server_errors && server_errors['slot']"
                              class="text-danger"
                            >{{ server_errors['slot'][0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Field: date -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="6"
                      >
                        <b-form-group
                          label="Date de création"
                          label-for="date"
                        >
                          <b-form-input
                            id="date"
                            :value="moment(user.created_at).format('LLL')"
                            plaintext
                            placeholder="date"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Field: Banned -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="2"
                      >
                        <b-form-group
                          label="Compte banni"
                          label-for="banned"
                        >
                          <b-form-checkbox
                            v-model="user.banned"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{ user.banned ? 'oui' : 'non' }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <!-- Field: newsletter -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="2"
                      >
                        <b-form-group
                          label="Newsletter"
                          label-for="newsletter"
                        >
                          <b-form-checkbox
                            v-model="user.newsletter"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{ user.newsletter ? 'oui' : 'non' }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Notification -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="2"
                      >
                        <b-form-group
                          label="Notification"
                          label-for="newsletter"
                        >
                          <b-form-checkbox
                            v-model="user.notification"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{ user.notification ? 'oui' : 'non' }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>

                      <!-- Field: Tutoriel -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="2"
                      >
                        <b-form-group
                          label="Tutoriel"
                          label-for="Tutoriel"
                        >
                          <b-form-checkbox
                            v-model="user.tutorial"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{ user.tutorial ? 'oui' : 'non' }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>

                      <!-- Field: verified email -->
                      <b-col
                        cols="12"
                        md="6"
                        lg="2"
                      >
                        <b-form-group
                          label="Email verifié"
                          label-for="email_verified"
                        >
                          <b-form-checkbox
                            v-model="user.email_verified"
                            :value="true"
                            :unchecked-value="false"
                          >
                            {{ user.email_verified ? 'oui' : 'non' }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- Action Buttons -->
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="formUserLoading || !isUserModified"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    >
                      <b-spinner
                        v-show="formUserLoading"
                        small
                      />
                      Enregistrer
                    </b-button>
                  </b-form>
                </validation-observer>
              </div>
            </b-col>
            <b-col lg="4">
              <b-card
                no-body
                class="border"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">Permission</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="roleData"
                >
                  <template #cell(role)="data">
                    {{ data.value }}
                  </template>
                  <template #cell()="data">
                    <b-form-checkbox
                      v-model="data.value.value"
                      :disabled="data.value.isDisable"
                      :value="true"
                      :unchecked-value="false"
                    />
                  </template>
                </b-table>
              </b-card>

              <!-- Action Buttons -->
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="formRoleLoading || !isUserRoleModified"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="updateRole"
              >
                <b-spinner
                  v-show="formRoleLoading"
                  small
                />
                Mettre à jour le rôle
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BLink,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import axiosIns from '@/libs/axios'
import {
  required, max, min, max_value, min_value,
} from '@validations'

export default {
  name: 'UsersEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BSpinner,
    BLink,
    BFormTextarea,
  },
  data() {
    return {
      loading: true,
      formUserLoading: false,
      formRoleLoading: false,
      languagesOptions: [],
      language: null,
      required,
      max,
      min,
      max_value,
      min_value,
      user: {},
      userReference: null,
      server_errors: null,
      roleData: [
        {
          role: 'Oui/Non',
          Admin: { value: true, isDisable: false },
          Joueur: { value: true, isDisable: true },
          SuperAdmin: { value: false, isDisable: true },
        },
      ],
      roleDataReference: null,
    }
  },
  computed: {
    isUserModified() {
      return JSON.stringify(this.user) !== this.userReference || (this.user.language ? this.user.language.id : null) !== this.language.value
    },
    isUserRoleModified() {
      return JSON.stringify(this.roleData) !== this.roleDataReference
    },
  },
  mounted() {
    this.fetchUser()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchUser() {
      try {
        this.loading = true
        const user = await axiosIns.get(`users/${this.$route.params.id}`)
        this.userReference = JSON.stringify(user.data.data)
        this.user = user.data.data
        this.roleData[0].Admin.value = this.user.isAdmin
        this.roleData[0].Joueur.value = this.user.isPlayer
        this.roleData[0].SuperAdmin.value = this.user.isSuperAdmin
        this.roleDataReference = JSON.stringify(this.roleData)
        const languages = await axiosIns.get(`${process.env.VUE_APP_BASE_API_URL}/availableLanguages`)
        languages.data.data.forEach(el => {
          this.languagesOptions.push({
            label: el.language,
            value: el.id,
          })
        })
        if (this.user.language) {
          this.language = {
            label: this.user.language.language,
            value: this.user.language.id,
          }
        }
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.user.name,
          breadcrumb: [
            {
              text: 'Utilisateurs',
              active: false,
              to: {
                name: 'users',
              },
            },
            {
              text: this.user.name,
              active: false,
              to: {
                name: 'users-view',
                params: { id: this.user.uuid },
              },
            },
            {
              text: 'éditer',
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitForm() {
      try {
        this.formUserLoading = true
        this.server_errors = null
        const data = {
          name: this.user.name,
          email: this.user.email,
          description: this.user.description,
          language_id: this.language ? this.language.value : null,
          notification: this.user.notification,
          newsletter: this.user.newsletter,
          tutorial: this.user.tutorial,
          email_verified: this.user.email_verified,
          banned: this.user.banned,
        }
        const user = await axiosIns.post(`users/${this.$route.params.id}`, data)
        this.userReference = JSON.stringify(user.data.data)
        this.user = user.data.data
        this.roleData[0].Admin.value = this.user.isAdmin
        this.roleData[0].Joueur.value = this.user.isPlayer
        this.roleData[0].SuperAdmin.value = this.user.isSuperAdmin
        this.roleDataReference = JSON.stringify(this.roleData)
        if (this.user.language) {
          this.language = {
            label: this.user.language.language,
            value: this.user.language.id,
          }
        }
        this.formUserLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'utilisateur à bien été mis à jour',
            },
          },
          { timeout: 5000 },
        )
      } catch (err) {
        this.formUserLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async updateRole() {
      try {
        this.server_errors = null
        this.formRoleLoading = true
        const data = {
          isAdmin: this.roleData[0].Admin.value,
        }
        const user = await axiosIns.post(`users/${this.$route.params.id}/roles`, data)
        this.user.isAdmin = user.data.data.isAdmin
        this.user.isPlayer = user.data.data.isPlayer
        this.user.isSuperAdmin = user.data.data.isSuperAdmin
        this.userReference = JSON.stringify(this.user)
        this.roleDataReference = JSON.stringify(this.roleData)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'utilisateur à bien été mis à jour',
            },
          },
          { timeout: 5000 },
        )
        this.formRoleLoading = false
      } catch (err) {
        this.formRoleLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    validationForm() {
      this.$refs.platformValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
